import { LinkWrapper } from 'components/common/LinkWrapper';
import { Colors } from 'constants/Colors';
import { useIsMobile } from 'hooks/useIsMobile';
import React from 'react';
import { Box, Text, Flex, Image } from 'rebass';
import { StyleObject } from 'types/styles';

export const MainMembershipIntroduction = () => {
  const isMobile = useIsMobile();
  return (
    <Flex sx={styles.container}>
      <Flex sx={styles.wrapper}>
        <Flex sx={styles.titleWrapper}>
          <Text sx={styles.title}>
            <b>정기 모임 멤버</b>가 되면
            <br />
            이런 혜택이 따라 와요!
          </Text>
          {!isMobile && (
            <LinkWrapper href="/salons">
              <Flex sx={styles.button}>
                <Text>지금 참여하기</Text>
                <Image sx={styles.arrow} src="/apply_arrow_large.svg" />
              </Flex>
            </LinkWrapper>
          )}
        </Flex>
        <Flex sx={styles.itemWrapper}>
          <Flex sx={styles.item}>
            <Box sx={styles.imageWrapper}>
              <Flex sx={styles.number}>1</Flex>
              <Image sx={styles.image} src="/membership_1.webp" />
            </Box>
            <Text sx={styles.itemText}>
              동행 · 소모임
              <br />
              <b>멤버십 평생 소장</b>
            </Text>
          </Flex>
          <Flex sx={styles.item}>
            <Box sx={styles.imageWrapper}>
              <Flex sx={styles.number}>2</Flex>
              <Image sx={styles.image} src="/membership_2.webp" />
            </Box>
            <Text sx={styles.itemText}>
              <b>4만원 상당</b>의
              <br />
              놀러가기 1회권
            </Text>
          </Flex>
          <Flex sx={styles.item}>
            <Box sx={styles.imageWrapper}>
              <Flex sx={styles.number}>3</Flex>
              <Image sx={styles.image} src="/membership_3.webp" />
            </Box>
            <Text sx={styles.itemText}>
              넷플연가 이벤트
              <br />
              <b>멤버 특별 할인</b>
            </Text>
          </Flex>
        </Flex>
        {isMobile && (
          <LinkWrapper href="/salons">
            <Flex sx={styles.button}>
              <Text>지금 참여하기</Text>
              <Image sx={styles.arrow} src="/apply_arrow_small.svg" />
            </Flex>
          </LinkWrapper>
        )}
      </Flex>
    </Flex>
  );
};

const styles: StyleObject = {
  arrow: {
    height: ['18px', '20px'],
    ml: ['3px', '6px'],
    width: ['18px', '20px'],
  },
  button: {
    alignItems: 'center',
    bg: Colors.gray11,
    borderRadius: ['25px', '30px'],
    color: 'white',
    fontSize: ['16px', '20px'],
    fontWeight: 'bold',
    height: ['50px', '60px'],
    justifyContent: 'center',
    mt: ['40px', '0px'],
    width: ['100%', '320px'],
  },
  container: {
    alignItems: 'center',
    bg: 'white',
    flexDirection: 'column',
    my: ['30px', '50px'],
    px: ['20px', '0px'],
    width: '100%',
  },
  image: {
    height: ['80px', '120px'],
    p: '4px',
    width: ['80px', '120px'],
    borderRadius: '100px',
    minHeight: ['80px', '120px'],
    minWidth: ['80px', '120px'],
  },
  imageWrapper: {
    position: 'relative',
  },
  item: {
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
  },
  itemText: {
    color: Colors.gray66,
    fontSize: ['12px', '16px'],
    lineHeight: ['17px', '23px'],
    mt: ['10px', '20px'],
    textAlign: 'center',
  },
  itemWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    ml: ['4px', '110px'],
    mr: ['4px', '0px'],
  },
  number: {
    alignItems: 'center',
    bg: Colors.gray11,
    borderRadius: ['13px', '18px'],
    color: 'white',
    fontSize: ['16px', '20px'],
    fontWeight: 500,
    height: ['26px', '36px'],
    justifyContent: 'center',
    left: '0px',
    position: 'absolute',
    top: '0px',
    width: ['26px', '36px'],
  },
  title: {
    color: Colors.gray11,
    fontSize: ['20px', '30px'],
    mb: ['40px', '46px'],
  },
  titleWrapper: {
    flexDirection: 'column',
  },
  wrapper: {
    borderBottom: ['none', `1px solid ${Colors.bluishGrayda}`],
    flexDirection: ['column', 'row'],
    maxWidth: '1000px',
    pb: ['0px', '100px'],
    width: '100%',
  },
};
