import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Flex, Image, Text } from 'rebass';
import { StyleObject } from 'types/styles';
import Flicking, { SelectEvent } from '@egjs/react-flicking';
import { Colors } from 'constants/Colors';
import { isMobile as isMobileDetected } from 'react-device-detect';
import { BookmarkButtonType, Meetup, WishType } from 'types/interface';
import { format24hrDate } from 'utils/TextUtils';
import {
  getResizedMeetupThumbnailImage,
  isMeetupBeforeOpening,
  isMeetupImminentToSoldOut,
  isMeetupNew,
  isMeetupSoldOut,
  meetupImminentText,
} from 'utils/MeetupUtil';
import { HomeMeetupListSection } from 'templates/Main';
import { BookmarkButton } from 'components/Bookmark/BookmarkButton';
import { LinkWrapper } from './LinkWrapper';
import { ListLoading } from './ListLoading';

interface HorizontalCardSectionProps {
  data: HomeMeetupListSection;
  showMoreButton?: boolean;
  isLoading?: boolean;
}

export const HorizontalCardSection = ({
  data,
  showMoreButton = true,
  isLoading
}: HorizontalCardSectionProps) => {
  const section = useRef<Flicking>();
  const [isMobile, setIsMobile] = useState(isMobileDetected);
  const handleResize = () => {
    setIsMobile(window && window.innerWidth < 640);
  };
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onClickArrow = useCallback(
    (direction: number) => () => {
      if (section.current.animating) {
        return;
      }
      const { index, panelCount } = section.current;
      const interval = isMobile ? 1 : 4;
      if (direction === 1) {
        section.current.moveTo(
          panelCount - (interval + index) < 1 ? panelCount - 1 : index + interval,
        );
      } else {
        section.current.moveTo(index - interval < 0 ? 0 : index - interval);
      }
    },
    [isMobile],
  );
  const onClickItem = useCallback(({ index }: SelectEvent<Flicking>) => {
    // TODO:  Event tracking
    // onClickMeeting(index);
  }, []);

  const getImageStyle = useCallback(
    (meetup: Meetup) => ({
      ...styles.image,
      backgroundImage: `url(${getResizedMeetupThumbnailImage(meetup, [220, 330])})`, // Desktop 사이즈 계산이 불가능해, 임의로 모바일 1.5배 설정
    }),
    [],
  );

  const getItemStyle = useCallback(
    (index: number) => ({
      ...styles.item,
      ml: index === 0 ? 0 : ['10px', '20px'],
      pl: index === 0 ? ['20px', '0px'] : 0,
      width:
        index === 0 || index === data.items.length - 1
          ? ['220px', 'calc(25% - 15px)']
          : ['200px', 'calc(25% - 15px)'],
    }),
    [data.items.length],
  );

  return (
    <Flex sx={styles.container}>
      <Flex sx={styles.titleWrapper}>
        <Text sx={styles.title}>{data.title}</Text>
        {showMoreButton && (
          <LinkWrapper href={data.destinationUrl}>
            <Flex sx={styles.moreButton}>
              <Text sx={styles.moreButtonText}>더 보기</Text>
            </Flex>
          </LinkWrapper>
        )}
      </Flex>
      <Box sx={styles.itemContainer}>
        <Flicking
          align={{
            camera: '0px',
            panel: isMobile ? '-20px' : '0px',
          }}
          // TODO: 오픈 예정 태그 추가
          bounce="0%"
          ref={section}
          bound
          moveType={isMobile ? 'freeScroll' : 'snap'}
          onSelect={onClickItem}
          duration={500}>
          {data.items.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box sx={getItemStyle(index)} key={`horizontal-card-item-${item.meetup.id}-${index}`}>
              <LinkWrapper href={`/meetups/${item.meetup.id}`} sx={styles.link}>
                <>
                  <Box sx={getImageStyle(item.meetup)}>
                    {isMeetupSoldOut(item.meetup) && (
                      <Flex sx={styles.soldOutWrapper}>
                        <Flex sx={styles.soldOutTag}>모집 마감</Flex>
                      </Flex>
                    )}
                    {!isMeetupSoldOut(item.meetup) && isMeetupImminentToSoldOut(item.meetup) && (
                      <Flex sx={styles.imminentWrapper}>
                        <Flex sx={styles.imminentTag}>{meetupImminentText(item.meetup)}</Flex>
                      </Flex>
                    )}
                    {isMeetupBeforeOpening(item.meetup) && (
                      <Flex sx={styles.imminentWrapper}>
                        <Flex sx={styles.soldOutTag}>오픈 예정</Flex>
                      </Flex>
                    )}
                    {!isMeetupBeforeOpening(item.meetup) &&
                      !isMeetupImminentToSoldOut(item.meetup) &&
                      isMeetupNew(item.meetup) && (
                        <Flex sx={styles.imminentWrapper}>
                          <Flex sx={styles.imminentTag}>NEW</Flex>
                        </Flex>
                      )}
                  </Box>
                  <Flex sx={styles.textWrapper}>
                    <Text sx={styles.itemTitle}>{item.meetup.title}</Text>
                    <Text sx={styles.itemSubText}>
                      {format24hrDate(item.meetup.sessions[0]?.date)}
                    </Text>
                  </Flex>
                </>
              </LinkWrapper>
              <BookmarkButton
                meetup={item.meetup}
                bookmarked={item.wishes.some(wish => wish.type === WishType.TYPE_BOOKMARK)}
                ButtonType={BookmarkButtonType.CARD_SECTION}
              />
            </Box>
          ))}
        </Flicking>
      </Box>
      {!isMobile && (
        <Flex sx={styles.arrowWrapper}>
          <Flex sx={styles.arrowButton} onClick={onClickArrow(0)}>
            <Image sx={styles.arrowImage} src="/arrow_left_large.svg" />
          </Flex>
          <Flex sx={styles.arrowButton} onClick={onClickArrow(1)}>
            <Image sx={styles.arrowImage} src="/arrow_right_large.svg" />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

const styles: StyleObject = {
  arrowButton: {
    '::selection': {
      background: 'transparent',
    },
    ':hover': {
      opacity: 0.8,
    },
    alignItems: 'center',
    bg: 'white',
    border: `1px solid ${Colors.bluishGrayda}`,
    borderRadius: ['13px', '20px'],
    cursor: 'pointer',
    height: ['26px', '40px'],
    justifyContent: 'center',
    ml: ['10px', '0px'],
    userSelect: 'none',
    width: ['26px', '40px'],
  },
  arrowImage: {
    height: ['16px', '20px'],
    width: ['16px', '20px'],
  },
  arrowWrapper: {
    alignItems: 'center',
    justifyContent: ['right', 'space-between'],
    left: ['none', '10px'],
    position: 'absolute',
    right: ['20px', '10px'],
    top: ['-2px', '130px'],
    zIndex: 2,
  },
  container: {
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: ['100%', '1140px'],
    mx: 'auto',
    my: ['30px', '36px'],
    position: 'relative',
    px: ['0px', '20px'],
    width: '100%',
  },
  image: {
    backgroundSize: 'cover',
    borderRadius: '4px',
    height: ['140px', 'auto'],
    overflow: 'hidden',
    position: 'relative',
    pt: ['0px', '76%'],
    width: '100%',
  },
  imminentTag: {
    bg: Colors.imminentRed,
    borderRadius: '4px',
    color: 'white',
    fontSize: ['10px', '12px'],
    fontWeight: 400,
    mb: 'auto',
    px: '6px',
    py: ['4px', '6px'],
  },
  imminentWrapper: {
    bottom: 0,
    left: 0,
    p: ['10px', '15px'],
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 10,
  },
  item: {
    flexDirection: 'column',

    position: 'relative',
    // mx: '10px',
    width: ['200px', 'calc(25% - 15px)'],
  },
  itemContainer: {
    maxWidth: '1000px',
    width: '100%',
  },
  itemSubText: {
    color: Colors.gray66,
    fontSize: ['12px', '15px'],
  },
  itemTitle: {
    color: Colors.gray11,
    fontSize: ['13px', '16px'],
    fontWeight: 'bold',
  },
  itemWrapper: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    cursor: 'pointer',
    height: ['300px', '380px'],
    overflow: 'hidden',
    width: '100%',
  },
  moreButton: {
    alignItems: 'center',
    bg: 'white',
    border: `1px solid ${Colors.bluishGrayda}`,
    borderRadius: ['18px', '20px'],
    color: Colors.bluishGray82,
    cursor: 'pointer',
    display: 'flex',
    flexWrap: 'wrap',
    height: ['36px', '40px'],
    px: ['14px', '13px'],
  },
  moreButtonArrow: {
    height: ['12px', '16px'],
    mt: ['1px', '3px'],
    width: ['12px', '16px'],
  },
  moreButtonText: {
    color: Colors.bluishGray82,
    fontSize: ['13px', '16px'],
    ml: ['2px', '7px'],
    mr: ['2px', '7px'],
  },
  soldOutTag: {
    bg: Colors.gray11,
    borderRadius: '4px',
    color: 'white',
    fontSize: ['10px', '12px'],
    fontWeight: 400,
    mb: 'auto',
    px: '6px',
    py: ['4px', '6px'],
  },
  soldOutWrapper: {
    bg: 'rgba(0, 0, 0, 0.36)',
    bottom: 0,
    left: 0,
    p: ['10px', '15px'],
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 10,
  },
  textWrapper: {
    flexDirection: 'column',
    height: ['73px', '114px'],
    justifyContent: 'space-between',
    mt: ['10px', '20px'],
    width: '100%',
  },
  title: {
    color: Colors.gray11,
    fontSize: ['20px', '24px'],
    fontWeight: 'bold',
    mr: 'auto',
  },
  titleWrapper: {
    alignItems: 'center',
    maxWidth: '1000px',
    mb: ['20px', '25px'],
    px: ['20px', '0px'],
    width: '100%',
  },
  wishIcon: {
    cursor: 'pointer',
    height: ['20px', '26px'],
    position: 'absolute',
    right: ['12px', '15px'],
    top: ['12px', '15px'],
    width: ['20px', '26px'],
    zIndex: 15,
  },
};
