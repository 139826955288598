import React, { useCallback, useRef, useState } from 'react';
import { Box, Flex, Text, Image } from 'rebass';
import { StyleObject } from 'types/styles';
import Flicking, { SelectEvent } from '@egjs/react-flicking';
import { AutoPlay } from '@egjs/flicking-plugins';
import { nl2br } from 'utils/TextUtils';
import { Colors } from 'constants/Colors';
import { isMobile as isMobileDetected } from 'react-device-detect';
import { HomeBanner } from 'templates/Main';
import { LinkWrapper } from './LinkWrapper';

interface CarouselProps {
  data: HomeBanner[];
}

export const Carousel = ({ data }: CarouselProps) => {
  const banner = useRef<Flicking>();
  const plugins = [new AutoPlay({ direction: 'NEXT', duration: 4000, stopOnHover: false })];
  const [homeBanner, setHomeBanner] = useState(data);
  const [lastPlayedTime, setLastPlayedTime] = useState(0);
  const [isMobile, setIsMobile] = useState(isMobileDetected);
  const [bannerIndex, setBannerIndex] = useState(0);
  const timeOutId = useRef<NodeJS.Timeout>(null);
  const autoPlayAnim = useCallback(() => {
    clearTimeout(timeOutId.current);
    if (!timeOutId && banner && banner.current && new Date().getTime() - lastPlayedTime >= 5000) {
      timeOutId.current = setTimeout(() => {
        if (!banner.current.animating) {
          setLastPlayedTime(new Date().getTime());
          banner.current.next(600);
        }
      }, 5000);
    }
  }, [lastPlayedTime, timeOutId]);

  const onHoldStart = useCallback(() => {
    clearTimeout(timeOutId.current);
  }, [timeOutId]);

  const onChanged = useCallback(
    e => {
      autoPlayAnim();
      setBannerIndex(e.index);
    },
    [autoPlayAnim, setBannerIndex],
  );

  const getItemStyle = useCallback((image: string) => {
    const defaultStyle: any = styles.itemWrapper;

    defaultStyle.backgroundImage = `url(${image})`;
    return { ...defaultStyle };
  }, []);

  const onClickItem = useCallback(({ index }: SelectEvent<Flicking>) => {
    // onClickMeeting(index);
  }, []);

  const onClickArrow = useCallback(
    (direction: number) => () => {
      if (banner.current.animating) {
        return;
      }
      const { index, panelCount } = banner.current;
      const interval = 1;
      if (direction === 1) {
        banner.current.moveTo(panelCount - (interval + index) < 1 ? 0 : index + interval);
      } else {
        banner.current.moveTo(index - interval < 0 ? panelCount - 1 : index - interval);
      }
    },
    [],
  );
  const onClickIndicator = useCallback(
    (direction: number) => () => {
      if (banner && banner.current) {
        banner.current.moveTo(direction, 600);
        autoPlayAnim();
      }
    },
    [banner, autoPlayAnim],
  );

  return (
    <Box sx={styles.container}>
      <Flicking
        plugins={plugins}
        align={{
          camera: '0px',
          panel: '0px',
        }}
        ref={banner}
        onChanged={onChanged}
        onHoldStart={onHoldStart}
        bound
        circular
        bounce="0%"
        panelsPerView={1}
        onSelect={onClickItem}
        duration={500}>
        {homeBanner.map(item => (
          <Flex
            sx={{
              ...styles.itemWrapper,
              backgroundImage: [`url(${item.image})`, `url(${item.desktopImage || item.image})`],
              position: 'relative',
            }}
            key={`home-carousel-banner-${item.title}`}>
            <LinkWrapper href={item.destinationUrl} sx={{ mr: 'auto'}} target={item.target} zIndex={2}>
              <Flex sx={styles.innerWrapper}>
                <Flex sx={styles.textWrapper}>
                  <Text sx={styles.description}>{nl2br(item.description)}</Text>
                  <Text sx={styles.title}>{nl2br(item.title)}</Text>
                </Flex>
              </Flex>
            </LinkWrapper>
            {item.opacity && item.opacity > 0 && (
              <Flex
                sx={{
                  backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, ${item.opacity}) 0%, rgba(0, 0, 0, 0) 60%)`,
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  zIndex: 1,
                }}
              />
            )}
          </Flex>
        ))}
      </Flicking>
      <Flex sx={styles.indicatorWrapper}>
        {data.map((d, index) => (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={`home-carousel-banner-indicator-${index}`}
            onClick={onClickIndicator(index)}
            sx={{
              opacity: index === bannerIndex ? 1 : 0.5,
              ...styles.indicator,
            }}
          />
        ))}
      </Flex>
      <Flex sx={styles.arrowWrapper}>
        <Flex sx={styles.arrowButton} onClick={onClickArrow(0)}>
          <Image
            sx={styles.arrowImage}
            src={isMobile ? '/arrow_left_small_white.svg' : '/arrow_left_large.svg'}
          />
        </Flex>
        <Flex sx={styles.arrowButton} onClick={onClickArrow(1)}>
          <Image
            sx={styles.arrowImage}
            src={isMobile ? '/arrow_right_small_white.svg' : '/arrow_right_large.svg'}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

const styles: StyleObject = {
  arrowButton: {
    '::selection': {
      background: 'transparent',
    },
    ':hover': {
      opacity: '1',
      transition: 'all 0.4s ease 0s',
    },
    alignItems: 'center',
    bg: ['#11111180', 'white'],
    border: ['1px solid white', `1px solid ${Colors.bluishGrayda}`],
    borderRadius: ['13px', '20px'],
    cursor: 'pointer',
    height: ['26px', '40px'],
    justifyContent: 'center',
    ml: ['10px', '0px'],
    opacity: ['0.7', '1'],
    userSelect: 'none',
    width: ['26px', '40px'],
  },
  arrowImage: {
    height: ['16px', '20px'],
    width: ['16px', '20px'],
  },
  arrowWrapper: {
    alignItems: 'center',
    bottom: ['35px', 'calc(50% - 20px)'],
    justifyContent: ['right', 'space-between'],
    left: ['none', '-60px'],
    position: 'absolute',
    right: ['20px', '-60px'],
    zIndex: 2,
  },
  button: {
    alignItems: 'center',
    bg: 'white',
    borderRadius: ['20px', '24px'],
    color: Colors.gray11,
    fontWeight: 'bold',
    height: ['40px', '44px'],
    mr: 'auto',
    mt: ['16px', '22px'],
    px: ['14px', '24px'],
  },
  container: {
    borderRadius: ['0px', '12px'],
    boxShadow: ['none', '0px 2px 16px 0px rgba(0, 0, 0, .16)'],
    maxWidth: '1000px',
    mb: '10px',
    mt: ['0px', '24px'],
    position: 'relative',
    width: '100%',
  },
  description: {
    color: 'white',
    fontSize: ['12px', '16px'],
    fontWeight: 500,
    letterSpacing: '-0.02em',
    lineHeight: '1.3',
    mb: ['6px', '8px'],
  },
  indicator: {
    bg: 'white',
    cursor: 'pointer',
    height: ['2px', '2.5px'],
    mx: ['2px', '4px'],
    width: ['32px', '48px'],
  },
  indicatorWrapper: {
    bottom: ['35px', '50px'],
    justifyContent: 'left',
    left: ['30px', 'calc(50% - 456px)'],
    position: 'absolute',
    zIndex: 2,
  },
  innerWrapper: {
    flexDirection: 'column',
    height: '100%',
    maxWidth: '1000px',
    minHeight: '225px',
    minWidth: ['330px', '1000px'],
    mx: 'auto',
    pb: ['52px', '75px'],
    pl: ['0px', '48px'],
    pr: ['20px', '48px'],
    width: '100%',
  },
  itemWrapper: {
    alignItems: 'center',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: ['0px', '12px'],
    // boxShadow: ['none', '0px 2px 16px 0px rgba(0, 0, 0, .16)'],
    cursor: 'pointer',
    flexDirection: 'column',
    height: ['300px', '360px'],
    justifyContent: 'flex-end',
    overflow: 'hidden',
    width: '100%',
  },
  textWrapper: {
    flexDirection: 'column',
    mt: 'auto',
  },
  title: {
    color: 'white',
    fontSize: ['24px', '32px'],
    fontWeight: 700,
    letterSpacing: '-0.02em',
    lineHeight: '1.25',
    mb: ['8px', '10px'],
  },
};
