import React, { useState } from 'react';
import { HomeBanner, HomeMeetupListSection, Main } from 'templates/Main';
import { PageTemplate } from 'templates/PageTemplate';
import { MeetupApi } from 'utils/Api/MeetupApi';
import {
  AuthToken,
  MeetupOrderType,
  MeetupsResponse,
  MeetupType,
  User,
  WishType,
} from 'types/interface';
import { withAuthorization } from 'utils/withAuthorization';
import _ from 'lodash';
import { PageContainer } from 'templates/PageContainer';
import { CURATION_TAG_ASSETS_MAP, CurationTagNames } from 'constants/Constants';
import {
  getFilteredMeetupsByGroupId,
  isMeetupSoldOut,
  MIN_REQUIREMENT_WISH_COUNT_FOR_OPENING,
} from 'utils/MeetupUtil';

const META = {
  description: '당신의 영화같은 사생활',
  ogUrl: '/nfyg_temp_og.png',
  title: '넷플연가 - 당신의 영화같은 사생활',
};

const BANNERS: HomeBanner[] = [
  {
    description: '새로운 커뮤니티의 기준',
    desktopImage:
      'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/c941f4a8-ee09-4450-7879-7b3a6e51f500/gamma=0',
    destinationUrl: '/welcome-interview',
    image: 'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/de951e1b-52aa-4163-4263-b6cca00ae900/gamma=0',
    target: '_self',
    title: '넷플연가 팀의\n새로운 실험과 도전 →',
  },
  {
    description: '사는 재미는 함께 만드는 거니까!',
    desktopImage:
      'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/fe581f56-9079-4fcb-ecb3-eedbc055d200/gamma=0',
    destinationUrl: 'https://story.nfyg.co',
    image: '/home-banner/banner-mobile-2-240725.png',
    target: '_blank',
    title: '넷플연가 모임,\n그 뒷 이야기들 →',
  },
  {
    description: '후기가 보증하는 최고의 모임들',
    desktopImage: '/home-banner/banner-PC-1-240725.png',
    destinationUrl: 'https://dub.sh/salons-review-desc',
    image: '/home-banner/banner-mobile-1-240725.png',
    target: '_self',
    title: '열리면 놓칠 수 없는\nBEST 모임! →',
  },
  {
    description: '넷플연가, 더 알고 싶다면?',
    desktopImage:
      'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/8e52d5b2-297d-489f-6147-6f1dc07f9000/gamma=0',
    destinationUrl: 'https://nfyg.co/community-reviews',
    image:
      'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/cea58405-bce6-4647-fb0a-ec1d3342f300/public',
    target: '_self',
    title: '함께한 멤버들의\n넷플연가 후기 →',
  },
  {
    description: '좋아하는 일로 사람들을 연결해보세요.',
    desktopImage:
      'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/7cc8acb7-4633-455a-102f-b8e242dacd00/gamma=0',
    destinationUrl: 'https://bit.ly/nfyg-host',
    image:
      'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/3e3632a3-4b58-4dce-1cc0-d9afb6070800/public',
    target: '_blank',
    title: '넷플연가\n모임장 모집 →',
  },
  // {
  //   description: '4월의 야외 이벤트들',
  //   desktopImage:
  //     'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/f13d0f36-37eb-43ca-14fc-cf51fb4bb500/gamma=0',
  //   destinationUrl: 'https://bit.ly/3THFYeK',
  //   image:
  //     'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/ff9a797d-87fb-440f-c848-9326b77ea800/public',
  //   target: '_self',
  //   title: '4월에는\n밖에서 만날까요? →',
  // },
  // {
  //   description: '신규 지역 모임 보러가기',
  //   desktopImage:
  //     'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/498085b0-dcdc-4774-fc08-565d44fbfb00/gamma=0',
  //   destinationUrl: 'https://bit.ly/4-life-sindang',
  //   image:
  //     'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/e3c99098-c5ec-4942-2dd9-303f92922300/public',
  //   target: '_self',
  //   title: '신당 사생활이\n오픈했어요! →',
  // },
  // {
  //   description: '다채로운 취향 디깅',
  //   desktopImage:
  //     'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/1e568ef2-4229-47f1-eba8-252505a81200/gamma=0',
  //   destinationUrl: 'https://bit.ly/events-nfyg',
  //   image:
  //     'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/a81aba8f-59b5-4f5c-0b5b-4b483720d600/public',
  //   target: '_self',
  //   title: `${new Date().getMonth() + 1}월 이벤트를\n소개합니다 →`,
  // },
  // {
  //   description: '2023 넷플연가 연말결산',
  //   desktopImage:
  //     'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/03766e58-cad4-4733-1c0f-fc8a7a9d4200/gamma=0',
  //   destinationUrl: 'https://bit.ly/nfyg-2023',
  //   image:
  //     'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/bed9b020-4027-4170-48ed-e3d8adb1a600/public',
  //   target: '_blank',
  //   title: `올 한 해 넷플연가가\n연결한 사람들은? →`,
  // },
  // {
  //   description: '넷플연가 팀의 지금',
  //   desktopImage:
  //     'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/e2e67f3f-6e38-4427-8f8d-54202f6f5100/gamma=0',
  //   destinationUrl: 'https://bit.ly/3GnwuPi',
  //   image:
  //     'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/096da618-e9ce-476b-2118-bbcef9bcc800/public',
  //   target: '_blank',
  //   title: '커뮤니티를 만들며\n고민하는 것들 →',
  // },
];

export const getServerSideProps = withAuthorization(async ({ authToken, user }) => {
  let [
    // eslint-disable-next-line prefer-const
    upcomingGatheringsResponse,
    // eslint-disable-next-line prefer-const
    recommendedEventsResponse,
    // eslint-disable-next-line prefer-const
    encoreSalonsResponse,
    upcomingSalonsResponse,
    // eslint-disable-next-line prefer-const
    imminentSalonResponse,
    // eslint-disable-next-line prefer-const
    comingSoonSalonResponse,
    // eslint-disable-next-line prefer-const
    soldOutSalonResponse,
    curatedSalons1Response,
    curatedSalons2Response,
    curatedSalons3Response,
    curatedSalons4Response,
    curatedSalons5Response,
    curatedSalons6Response,
    curatedSalons7Response,
    curatedSalons8Response,
    curatedSalons9Response,
  ] = await Promise.all([
    MeetupApi.getMeetups(
      {
        available: true,
        order: MeetupOrderType.SESSION_ASC,
        types: [MeetupType.GATHERING],
        upcoming: true,
      },
      { limit: 10 },
      authToken,
    ),
    MeetupApi.getMeetups(
      {
        available: true,
        order: MeetupOrderType.SESSION_ASC,
        raffleMeetup: false,
        types: [MeetupType.EVENT],
        upcoming: true,
      },
      { limit: 10 },
      authToken,
    ),
    MeetupApi.getMeetups(
      {
        available: true,
        order: MeetupOrderType.RELEASED_ASC,
        reopeningHost: true,
        types: [MeetupType.SALON],
        upcoming: true,
      },
      { limit: 8 },
      authToken,
    ),
    MeetupApi.getMeetups(
      {
        available: true,
        order: MeetupOrderType.SESSION_ASC,
        types: [MeetupType.SALON],
        upcoming: true,
      },
      { limit: 8 },
      authToken,
    ),
    MeetupApi.getMeetups(
      {
        imminent: true,
        order: MeetupOrderType.SPARE_SEAT_ASC,
        types: [MeetupType.SALON],
        upcoming: true,
      },
      { limit: 8 },
      authToken,
    ),
    MeetupApi.getMeetups(
      // comingSoonSalonResponse
      {
        beforeOpening: true,
        includingWishType: [WishType.TYPE_BOOKMARK, WishType.TYPE_OPEN_NOTIFICATION],
        order: MeetupOrderType.OPEN_NOTIFICATION_WISH_COUNT_DESC,
        types: [MeetupType.SALON],
      },
      { limit: 10 },
      authToken,
    ),
    MeetupApi.getMeetups(
      {
        available: false,
        order: MeetupOrderType.SESSION_ASC,
        types: [MeetupType.SALON],
        upcoming: false,
      },
      { limit: 8 },
      authToken,
    ),
    MeetupApi.getMeetups(
      {
        available: true,
        homeCuration: [CurationTagNames.TASTE_NEW],
        order: MeetupOrderType.SPARE_SEAT_ASC,
        types: [MeetupType.SALON],
        upcoming: true,
      },
      { limit: 8 },
      authToken,
    ),
    MeetupApi.getMeetups(
      {
        available: true,
        homeCuration: [CurationTagNames.DRINK_NEW],
        order: MeetupOrderType.SPARE_SEAT_ASC,
        types: [MeetupType.SALON],
        upcoming: true,
      },
      { limit: 8 },
      authToken,
    ),
    MeetupApi.getMeetups(
      {
        available: true,
        homeCuration: [CurationTagNames.EGO_NEW],
        order: MeetupOrderType.SPARE_SEAT_ASC,
        types: [MeetupType.SALON],
        upcoming: true,
      },
      { limit: 8 },
      authToken,
    ),
    MeetupApi.getMeetups(
      {
        available: true,
        homeCuration: [CurationTagNames.CAREER_NEW],
        order: MeetupOrderType.SPARE_SEAT_ASC,
        types: [MeetupType.SALON],
        upcoming: true,
      },
      { limit: 8 },
      authToken,
    ),
    MeetupApi.getMeetups(
      {
        available: true,
        homeCuration: [CurationTagNames.MOVIE_NEW],
        order: MeetupOrderType.SPARE_SEAT_ASC,
        types: [MeetupType.SALON],
        upcoming: true,
      },
      { limit: 8 },
      authToken,
    ),
    MeetupApi.getMeetups(
      {
        available: true,
        homeCuration: [CurationTagNames.CULTURE_NEW],
        order: MeetupOrderType.SPARE_SEAT_ASC,
        types: [MeetupType.SALON],
        upcoming: true,
      },
      { limit: 8 },
      authToken,
    ),
    MeetupApi.getMeetups(
      {
        available: true,
        homeCuration: [CurationTagNames.INTELLECTUAL_CONVERSION_NEW],
        order: MeetupOrderType.SPARE_SEAT_ASC,
        types: [MeetupType.SALON],
        upcoming: true,
      },
      { limit: 8 },
      authToken,
    ),
    MeetupApi.getMeetups(
      {
        available: true,
        homeCuration: [CurationTagNames.EAT_PLAY_LOVE_NEW],
        order: MeetupOrderType.SPARE_SEAT_ASC,
        types: [MeetupType.SALON],
        upcoming: true,
      },
      { limit: 8 },
      authToken,
    ),
    MeetupApi.getMeetups(
      {
        available: true,
        homeCuration: [CurationTagNames.LOVE_NEW],
        order: MeetupOrderType.SPARE_SEAT_ASC,
        types: [MeetupType.SALON],
        upcoming: true,
      },
      { limit: 8 },
      authToken,
    ),
  ]);

  if (upcomingGatheringsResponse.meetups.length < 4) {
    upcomingGatheringsResponse = await MeetupApi.getMeetups(
      {
        order: MeetupOrderType.SESSION_ASC,
        types: [MeetupType.GATHERING],
        upcoming: true,
      },
      { limit: 10 },
      authToken,
    );
  }

  if (recommendedEventsResponse.meetups.length < 4) {
    recommendedEventsResponse = await MeetupApi.getMeetups(
      {
        order: MeetupOrderType.SESSION_ASC,
        raffleMeetup: false,
        types: [MeetupType.EVENT],
        upcoming: true,
      },
      { limit: 10 },
      authToken,
    );
  }

  if (upcomingSalonsResponse.meetups.length < 4) {
    upcomingSalonsResponse = await MeetupApi.getMeetups(
      {
        order: MeetupOrderType.SESSION_ASC,
        types: [MeetupType.SALON],
        upcoming: true,
      },
      { limit: 10 },
      authToken,
    );
  }
  if (curatedSalons1Response.meetups.length < 4) {
    curatedSalons1Response = await MeetupApi.getMeetups(
      {
        homeCuration: [CurationTagNames.TASTE_NEW],
        order: MeetupOrderType.RELEASED_DESC,
        types: [MeetupType.SALON],
      },
      { limit: 10 },
    );
  }
  if (curatedSalons2Response.meetups.length < 4) {
    curatedSalons2Response = await MeetupApi.getMeetups(
      {
        homeCuration: [CurationTagNames.DRINK_NEW],
        order: MeetupOrderType.RELEASED_DESC,
        types: [MeetupType.SALON],
      },
      { limit: 10 },
      authToken,
    );
  }
  if (curatedSalons3Response.meetups.length < 4) {
    curatedSalons3Response = await MeetupApi.getMeetups(
      {
        homeCuration: [CurationTagNames.EGO_NEW],
        order: MeetupOrderType.RELEASED_DESC,
        types: [MeetupType.SALON],
      },
      { limit: 10 },
      authToken,
    );
  }
  if (curatedSalons4Response.meetups.length < 4) {
    curatedSalons4Response = await MeetupApi.getMeetups(
      {
        homeCuration: [CurationTagNames.CAREER_NEW],
        order: MeetupOrderType.RELEASED_DESC,
        types: [MeetupType.SALON],
      },
      { limit: 10 },
      authToken,
    );
  }
  if (curatedSalons5Response.meetups.length < 4) {
    curatedSalons5Response = await MeetupApi.getMeetups(
      {
        homeCuration: [CurationTagNames.MOVIE_NEW],
        order: MeetupOrderType.RELEASED_DESC,
        types: [MeetupType.SALON],
      },
      { limit: 10 },
      authToken,
    );
  }
  if (curatedSalons6Response.meetups.length < 4) {
    curatedSalons6Response = await MeetupApi.getMeetups(
      {
        homeCuration: [CurationTagNames.CULTURE_NEW],
        order: MeetupOrderType.RELEASED_DESC,
        types: [MeetupType.SALON],
      },
      { limit: 10 },
      authToken,
    );
  }
  if (curatedSalons7Response.meetups.length < 4) {
    curatedSalons7Response = await MeetupApi.getMeetups(
      {
        homeCuration: [CurationTagNames.INTELLECTUAL_CONVERSION_NEW],
        order: MeetupOrderType.RELEASED_DESC,
        types: [MeetupType.SALON],
      },
      { limit: 10 },
      authToken,
    );
  }
  if (curatedSalons8Response.meetups.length < 4) {
    curatedSalons8Response = await MeetupApi.getMeetups(
      {
        homeCuration: [CurationTagNames.EAT_PLAY_LOVE_NEW],
        order: MeetupOrderType.RELEASED_DESC,
        types: [MeetupType.SALON],
      },
      { limit: 10 },
      authToken,
    );
  }
  if (curatedSalons9Response.meetups.length < 4) {
    curatedSalons9Response = await MeetupApi.getMeetups(
      {
        homeCuration: [CurationTagNames.LOVE_NEW],
        order: MeetupOrderType.RELEASED_DESC,
        types: [MeetupType.SALON],
      },
      { limit: 10 },
      authToken,
    );
  }

  CURATION_TAG_ASSETS_MAP[CurationTagNames.TASTE_NEW].items = _.shuffle(
    curatedSalons1Response.meetups.filter(meetup => !isMeetupSoldOut(meetup.meetup)),
  ).concat(
    _.shuffle(curatedSalons1Response.meetups.filter(meetup => isMeetupSoldOut(meetup.meetup))),
  );
  CURATION_TAG_ASSETS_MAP[CurationTagNames.DRINK_NEW].items = _.shuffle(
    curatedSalons2Response.meetups.filter(meetup => !isMeetupSoldOut(meetup.meetup)),
  ).concat(
    _.shuffle(curatedSalons2Response.meetups.filter(meetup => isMeetupSoldOut(meetup.meetup))),
  );
  CURATION_TAG_ASSETS_MAP[CurationTagNames.EGO_NEW].items = _.shuffle(
    curatedSalons3Response.meetups.filter(meetup => !isMeetupSoldOut(meetup.meetup)),
  ).concat(
    _.shuffle(curatedSalons3Response.meetups.filter(meetup => isMeetupSoldOut(meetup.meetup))),
  );
  CURATION_TAG_ASSETS_MAP[CurationTagNames.CAREER_NEW].items = _.shuffle(
    curatedSalons4Response.meetups.filter(meetup => !isMeetupSoldOut(meetup.meetup)),
  ).concat(
    _.shuffle(curatedSalons4Response.meetups.filter(meetup => isMeetupSoldOut(meetup.meetup))),
  );
  CURATION_TAG_ASSETS_MAP[CurationTagNames.MOVIE_NEW].items = _.shuffle(
    curatedSalons5Response.meetups.filter(meetup => !isMeetupSoldOut(meetup.meetup)),
  ).concat(
    _.shuffle(curatedSalons5Response.meetups.filter(meetup => isMeetupSoldOut(meetup.meetup))),
  );
  CURATION_TAG_ASSETS_MAP[CurationTagNames.CULTURE_NEW].items = _.shuffle(
    curatedSalons6Response.meetups.filter(meetup => !isMeetupSoldOut(meetup.meetup)),
  ).concat(
    _.shuffle(curatedSalons6Response.meetups.filter(meetup => isMeetupSoldOut(meetup.meetup))),
  );
  CURATION_TAG_ASSETS_MAP[CurationTagNames.INTELLECTUAL_CONVERSION_NEW].items = _.shuffle(
    curatedSalons7Response.meetups.filter(meetup => !isMeetupSoldOut(meetup.meetup)),
  ).concat(
    _.shuffle(curatedSalons7Response.meetups.filter(meetup => isMeetupSoldOut(meetup.meetup))),
  );
  CURATION_TAG_ASSETS_MAP[CurationTagNames.EAT_PLAY_LOVE_NEW].items = _.shuffle(
    curatedSalons8Response.meetups.filter(meetup => !isMeetupSoldOut(meetup.meetup)),
  ).concat(
    _.shuffle(curatedSalons8Response.meetups.filter(meetup => isMeetupSoldOut(meetup.meetup))),
  );
  CURATION_TAG_ASSETS_MAP[CurationTagNames.LOVE_NEW].items = _.shuffle(
    curatedSalons9Response.meetups.filter(meetup => !isMeetupSoldOut(meetup.meetup)),
  ).concat(
    _.shuffle(curatedSalons9Response.meetups.filter(meetup => isMeetupSoldOut(meetup.meetup))),
  );

  const curatedMeetupChunks = _.shuffle([
    CURATION_TAG_ASSETS_MAP[CurationTagNames.TASTE_NEW],
    CURATION_TAG_ASSETS_MAP[CurationTagNames.CULTURE_NEW],
    CURATION_TAG_ASSETS_MAP[CurationTagNames.EGO_NEW],
    CURATION_TAG_ASSETS_MAP[CurationTagNames.LOVE_NEW],
    CURATION_TAG_ASSETS_MAP[CurationTagNames.CAREER_NEW],
    CURATION_TAG_ASSETS_MAP[CurationTagNames.MOVIE_NEW],
    CURATION_TAG_ASSETS_MAP[CurationTagNames.EAT_PLAY_LOVE_NEW],
    CURATION_TAG_ASSETS_MAP[CurationTagNames.INTELLECTUAL_CONVERSION_NEW],
    CURATION_TAG_ASSETS_MAP[CurationTagNames.DRINK_NEW],
  ]);

  return {
    props: {
      authToken,
      banners: BANNERS,
      comingSoonSalonMeetups: {
        destinationUrl: '/salons/coming-soon',
        items: comingSoonSalonResponse?.meetups ?? [],
        name: '먼저 둘러보고 오픈 알림 꾹',
        title: `🛎️ ${MIN_REQUIREMENT_WISH_COUNT_FOR_OPENING}명이 모이면 열려요!`,
      },
      curatedMeetups1: curatedMeetupChunks.pop(),
      curatedMeetups2: curatedMeetupChunks.pop(),
      curatedMeetups3: curatedMeetupChunks.pop(),
      curatedMeetups4: curatedMeetupChunks.pop(),
      curatedMeetups5: curatedMeetupChunks.pop(),
      curatedMeetups6: curatedMeetupChunks.pop(),
      curatedMeetups7: curatedMeetupChunks.pop(),
      curatedMeetups8: curatedMeetupChunks.pop(),
      curatedMeetups9: curatedMeetupChunks.pop(),
      imminentSalonMeetups: {
        destinationUrl: '/salons?filter=마감임박',
        items: _.shuffle(imminentSalonResponse?.meetups ?? []),
        name: 'imminent',
        title: '마감 임박! 인기 모임',
      },
      recommendedEventsResponse,
      reopeningHostSalonMeetups: {
        destinationUrl: '/salons?filter=첫%20시작%20추천%20모임&isAvailable=true',
        items: _.shuffle(encoreSalonsResponse?.meetups ?? []),
        name: 'reopeningHost',
        title: '만족도 99%, 첫 시작하기 좋은 모임 💗',
      },
      soldOutSalonMeetups: {
        destinationUrl: '/salons/curation?tag=soldOut',
        items: _.shuffle(soldOutSalonResponse?.meetups ?? []),
        name: '팔로우 가능 인기 모임',
        title: '이미 시작한 인기 모임도, 팔로우 신청 🎤',
      },
      upcomingGatheringsResponse,
      upcomingSalonMeetups: {
        destinationUrl: '/salons?filter=곧%20시작하는%20모임',
        items: upcomingSalonsResponse?.meetups ?? [],
        name: '바로 참여 가능',
        title: '기다리지 마세요 🚀 이번주 바로 참여 가능!',
      },
      user,
    },
  };
});

export interface MainProps {
  authToken: AuthToken;
  upcomingGatheringsResponse?: MeetupsResponse;
  recommendedEventsResponse?: MeetupsResponse;
  reopeningHostSalonMeetups: HomeMeetupListSection;
  upcomingSalonMeetups: HomeMeetupListSection;
  imminentSalonMeetups: HomeMeetupListSection;
  comingSoonSalonMeetups: HomeMeetupListSection;
  soldOutSalonMeetups: HomeMeetupListSection;
  curatedMeetups1: HomeMeetupListSection;
  curatedMeetups2: HomeMeetupListSection;
  curatedMeetups3: HomeMeetupListSection;
  curatedMeetups4: HomeMeetupListSection;
  curatedMeetups5: HomeMeetupListSection;
  curatedMeetups6: HomeMeetupListSection;
  curatedMeetups7: HomeMeetupListSection;
  curatedMeetups8: HomeMeetupListSection;
  curatedMeetups9: HomeMeetupListSection;
  banners: HomeBanner[];
  user: User;
}

const Home = ({
  banners,
  reopeningHostSalonMeetups,
  upcomingSalonMeetups,
  imminentSalonMeetups,
  comingSoonSalonMeetups,
  curatedMeetups1,
  curatedMeetups2,
  curatedMeetups3,
  curatedMeetups4,
  curatedMeetups5,
  curatedMeetups6,
  curatedMeetups7,
  curatedMeetups8,
  curatedMeetups9,
  soldOutSalonMeetups,
  upcomingGatheringsResponse,
  recommendedEventsResponse,
}: MainProps) => {
  const upcomingGatherings = upcomingGatheringsResponse.meetups;
  const recommendEvents = recommendedEventsResponse?.meetups
    ? getFilteredMeetupsByGroupId(recommendedEventsResponse.meetups)
    : undefined;
  const [comingSoonSalons, setComingSoonSalons] = useState(comingSoonSalonMeetups);

  return (
    <>
      <PageTemplate>
        <Main
          banners={banners}
          gatherings={upcomingGatherings}
          events={recommendEvents}
          reopeningHostSalonMeetups={reopeningHostSalonMeetups}
          upcomingSalonMeetups={upcomingSalonMeetups}
          imminentSalonMeetups={imminentSalonMeetups}
          comingSoonSalonMeetups={comingSoonSalons}
          curatedMeetups1={curatedMeetups1}
          curatedMeetups2={curatedMeetups2}
          curatedMeetups3={curatedMeetups3}
          curatedMeetups4={curatedMeetups4}
          curatedMeetups5={curatedMeetups5}
          curatedMeetups6={curatedMeetups6}
          curatedMeetups7={curatedMeetups7}
          curatedMeetups8={curatedMeetups8}
          curatedMeetups9={curatedMeetups9}
          soldOutSalonMeetups={soldOutSalonMeetups}
        />
      </PageTemplate>
    </>
  );
};

export default PageContainer(Home);
