import React, { useMemo } from 'react';
import { StyleObject } from 'types/styles';
import { Flex, Text, Image } from 'rebass';
import { Colors } from 'constants/Colors';
import { nl2br } from 'utils/TextUtils';
import { TabStatus } from 'constants/Constants';

interface SalonListEmptyProps {
  salonTabStatus: TabStatus;
}

export const SalonListEmpty = ({ salonTabStatus }: SalonListEmptyProps) => {
  const emptyData = useMemo(() => {
    switch (salonTabStatus) {
      case TabStatus.BEFORE_OPENING:
        return {
          img: '/salons/empty-closing.png',
          text:
            '지금은 오픈 예정 정기 모임이 없어요.\n신청 가능 탭과 대기 가능 탭을 확인해 보세요!',
        };
      case TabStatus.AVAILABLE:
        return {
          img: '/salons/empty-closing.png',
          text:
            '지금은 신청 가능 정기 모임이 없어요.\n오픈 예정 탭과 대기 가능 탭을 확인해 보세요!',
        };
      case TabStatus.WAITLIST:
        return {
          img: '/salons/empty-pre-open.png',
          text:
            '지금은 대기 가능 정기 모임이 없어요.\n오픈 예정 탭과 신청 가능 탭을 확인해 보세요!',
        };
      default:
        return {
          img: '/salons/empty-closing.png',
          text:
            '지금은 신청 가능 정기 모임이 없어요.\n오픈 예정 탭과 대기 가능 탭을 확인해 보세요!',
        };
    }
  }, [salonTabStatus]);

  return (
    <Flex sx={styles.nolist}>
      <Image sx={styles.emptyImg} src={emptyData.img} />
      <Text sx={styles.nolistText}>{nl2br(emptyData.text)}</Text>
    </Flex>
  );
};

const styles: StyleObject = {
  emptyImg: {
    border: `1px solid #1D2939`,
    borderRadius: '100%',
    height: ['144px', '160px'],
    mb: '24px',
    width: ['144px', '160px'],
  },
  nolist: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: ['300px', '350px'],
    width: '100%',
  },
  nolistText: {
    color: Colors.nfygBlack,
    fontSize: ['14px', '16px'],
    fontWeight: '400',
    lineHeight: '1.5',
    textAlign: 'center',
  },
};
