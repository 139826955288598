import React from 'react';
import { Flex } from 'rebass';
import { MeetupWithWishes, WishType } from 'types/interface';
import { StyleObject } from 'types/styles';
import { Colors } from 'constants/Colors';
import { TabStatus } from 'constants/Constants';
import { SalonItem } from './SalonItem';
import { SalonListEmpty } from './SalonListEmpty';
import { ListLoading } from 'components/common/ListLoading';

interface SalonListProps {
  meetups: MeetupWithWishes[];
  isMoreLoading?: boolean;
  salonTabStatus?: TabStatus;
  borderBottom?: boolean;
}

export const SalonList = ({
  meetups,
  isMoreLoading,
  salonTabStatus,
  borderBottom,
}: SalonListProps) => (
  <Flex sx={styles.container}>
    <Flex sx={styles.wrapper}>
      {meetups &&
        meetups.map((meetup, index) => (
          <SalonItem
            meetup={meetup.meetup}
            bookmarked={meetup.wishes.some(
              wish =>
                wish.type === WishType.TYPE_BOOKMARK &&
                String(wish.meetupId) === String(meetup.meetup.id),
            )}
            // eslint-disable-next-line react/no-array-index-key
            key={`salon-item-${meetup.meetup.id}-${index}`}
          />
        ))}
      {isMoreLoading && (
        <ListLoading 
          isInitialLoading={meetups.length === 0} 
          initialLoadingHeight="calc(100vh - 300px)"
        />
      )}
      {!isMoreLoading && meetups.length === 0 && <SalonListEmpty salonTabStatus={salonTabStatus} />}
      {borderBottom && <Flex sx={styles.borderLine} />}
    </Flex>
  </Flex>
);

const styles: StyleObject = {
  borderLine: {
    borderBottom: `1px solid ${Colors.nfygBlack}`,
    mb: ['20px', '30px'],
    mt: '30px',
    mx: 'auto',
    width: ['calc(100% - 10px)', 'calc(100% - 20px)'],
  },
  container: {
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    px: '15px',
    width: '100%',
  },
  loadingWrraper: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  wrapper: {
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    maxWidth: '1020px',
    width: '100%',
  },
};
