import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Flex, Image, Text } from 'rebass';
import { StyleObject } from 'types/styles';
import Flicking, { SelectEvent } from '@egjs/react-flicking';
import { Colors } from 'constants/Colors';
import { SalonList } from 'components/Salon/SalonList';
import { LogActions, logEventAction, LogScreens } from 'utils/Analytics';
import { useIsMobile } from 'hooks/useIsMobile';
import { HomeMeetupListSection } from 'templates/Main';
import { LinkWrapper } from './LinkWrapper';

interface HomeCurationProps {
  data: HomeMeetupListSection;
}

export const HomeCuration = ({ data }: HomeCurationProps) => {
  const section = useRef<Flicking>();
  const isMobile = useIsMobile();

  const onClickArrow = useCallback(
    (direction: number) => () => {
      if (section.current.animating) {
        return;
      }
      const { index, panelCount } = section.current;
      const currIndex = index < 0 ? 0 : index;
      const interval = isMobile ? 2 : 4;
      if (direction === 1) {
        section.current.moveTo(
          panelCount - (interval + currIndex) < 1 ? panelCount - 1 : currIndex + interval,
        );
      } else {
        section.current.moveTo(currIndex - interval < 0 ? 0 : currIndex - interval);
      }
    },
    [isMobile],
  );
  const onClickItem = useCallback(
    ({ index }: SelectEvent<Flicking>) => {
      logEventAction(LogScreens.MEETUP_DETAIL, LogActions.CLICK, 'salon_recommendation', {
        index,
        meetup_id: data.items[index]?.meetup.id,
      });
    },
    [data],
  );

  return (
    <Flex sx={styles.container}>
      <Flex sx={styles.titleWrapper}>
        <Text sx={styles.title}>{data.title}</Text>
      </Flex>
      <Box sx={styles.itemContainer}>
        <SalonList meetups={data.items.slice(0, 4)} />
        {/* {isMobile ? ( */}
        {/*  <SalonList meetups={data.items.slice(0, 4)} /> */}
        {/* ) : ( */}
        {/*  <Flicking */}
        {/*    align={{ */}
        {/*      camera: '0px', */}
        {/*      panel: '0px', */}
        {/*    }} */}
        {/*    // TODO: 오픈 예정 태그 추가 */}
        {/*    bounce="0%" */}
        {/*    ref={section} */}
        {/*    bound */}
        {/*    moveType="snap" */}
        {/*    useFindDOMNode */}
        {/*    onSelect={onClickItem} */}
        {/*    duration={500}> */}
        {/*    {data.items.map((item, index) => ( */}
        {/*      <HomeCollectionItem meetup={item} section={section} index={index} /> */}
        {/*    ))} */}
        {/*  </Flicking> */}
        {/* )} */}
      </Box>
      <Flex sx={styles.moreWrapper}>
        {/* TODO: 추후 destination url 로 모두 교체 */}
        <LinkWrapper href={data.destinationUrl ?? `/salons/curation?tag=${data.name}`}>
          <Flex sx={styles.moreButton}>
            <Text sx={styles.moreButtonText}>전체 보기</Text>
            {/* <Image sx={styles.moreButtonArrow} src="/more_arrow_large.svg" /> */}
          </Flex>
        </LinkWrapper>
      </Flex>
      {/* {!isMobile && ( */}
      {/*  <Flex sx={styles.arrowWrapper}> */}
      {/*    <Flex sx={styles.arrowButton} onClick={onClickArrow(0)}> */}
      {/*      <Image sx={styles.arrowImage} src="/arrow_left_large.svg" /> */}
      {/*    </Flex> */}
      {/*    <Flex sx={styles.arrowButton} onClick={onClickArrow(1)}> */}
      {/*      <Image sx={styles.arrowImage} src="/arrow_right_large.svg" /> */}
      {/*    </Flex> */}
      {/*  </Flex> */}
      {/* )} */}
    </Flex>
  );
};

const styles: StyleObject = {
  arrowButton: {
    '::selection': {
      background: 'transparent',
    },
    ':hover': {
      opacity: 0.8,
    },
    alignItems: 'center',
    bg: 'white',
    border: `1px solid ${Colors.bluishGrayda}`,
    borderRadius: ['13px', '20px'],
    cursor: 'pointer',
    height: ['26px', '40px'],
    justifyContent: 'center',
    ml: ['10px', '0px'],
    userSelect: 'none',
    width: ['26px', '40px'],
  },
  arrowImage: {
    height: ['16px', '20px'],
    width: ['16px', '20px'],
  },
  arrowWrapper: {
    alignItems: 'center',
    justifyContent: ['right', 'space-between'],
    left: ['none', '10px'],
    position: 'absolute',
    right: ['20px', '10px'],
    top: ['-2px', '180px'],
    zIndex: 2,
  },
  container: {
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: ['100%', '1140px'],
    my: ['30px', '36px'],
    position: 'relative',
    px: ['0px', '20px'],
    width: '100%',
  },
  image: {
    backgroundSize: 'cover',
    borderRadius: '4px',
    height: ['140px', 'auto'],
    overflow: 'hidden',
    position: 'relative',
    pt: ['0px', '76%'],
    width: '100%',
  },
  imminentTag: {
    bg: Colors.imminentRed,
    borderRadius: '4px',
    color: 'white',
    fontSize: ['10px', '12px'],
    fontWeight: 400,
    mb: 'auto',
    px: '6px',
    py: ['4px', '6px'],
  },
  imminentWrapper: {
    bottom: 0,
    left: 0,
    p: ['10px', '15px'],
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 10,
  },
  item: {
    flexDirection: 'column',
    // mx: '10px',
    width: ['200px', 'calc(25% - 15px)'],
  },
  itemContainer: {
    // maxWidth: '1000px',
    width: '100%',
  },
  itemSubText: {
    color: Colors.gray66,
    fontSize: ['12px', '15px'],
  },
  itemTitle: {
    color: Colors.gray11,
    fontSize: ['13px', '16px'],
    fontWeight: 'bold',
  },
  itemWrapper: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    cursor: 'pointer',
    height: ['300px', '380px'],
    overflow: 'hidden',
    width: '100%',
  },
  moreButton: {
    alignItems: 'center',
    bg: 'white',
    border: `1px solid ${Colors.bluishGrayda}`,
    borderRadius: ['18px', '20px'],
    color: Colors.bluishGray82,
    cursor: 'pointer',
    display: 'flex',
    flexWrap: 'wrap',
    height: ['36px', '40px'],
    px: ['14px', '13px'],
  },
  moreButtonArrow: {
    height: ['12px', '16px'],
    mt: ['1px', '3px'],
    width: ['12px', '16px'],
  },
  moreButtonText: {
    color: Colors.bluishGray82,
    fontSize: ['13px', '16px'],
    ml: ['2px', '7px'],
    mr: ['2px', '7px'],
  },
  moreWrapper: {
    justifyContent: ['center', 'flex-end'],
    mt: ['5px', '20px'],
    width: ['100%', '1000px'],
  },
  soldOutTag: {
    bg: Colors.gray11,
    borderRadius: '4px',
    color: 'white',
    fontSize: ['10px', '12px'],
    fontWeight: 400,
    mb: 'auto',
    px: '6px',
    py: ['4px', '6px'],
  },
  soldOutWrapper: {
    bg: 'rgba(0, 0, 0, 0.36)',
    bottom: 0,
    left: 0,
    p: ['10px', '15px'],
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 10,
  },
  textWrapper: {
    flexDirection: 'column',
    height: ['73px', '114px'],
    justifyContent: 'space-between',
    mt: ['10px', '20px'],
    width: '100%',
  },
  title: {
    color: Colors.gray11,
    fontSize: ['20px', '24px'],
    fontWeight: 'bold',
  },
  titleWrapper: {
    maxWidth: '1000px',
    mb: ['20px', '25px'],
    pl: ['20px', '0px'],
    width: '100%',
  },
};
